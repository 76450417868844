.cardEducation {
  margin: 3vh 0vw;
}

.cardEducation:hover {
  transform: scale(1.1);
}

.rowCotnainer {
  display: flex;
}

.centerX {
  display: flex;
  flex-direction: column;
}

.containersdsd {
  display: flex;
  flex-direction: column;
  padding-top: 10px !important;
}

.logos {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.avatar {
  align-self: center;
  width: 100px !important;
  height: 100px !important ;
  margin: 0px 45px !important;

  padding: 0px 10px;
}

.name {
  text-align: center;
  font-size: 20px !important;
  padding: 0px 0px !important;
  margin: 0px 0px !important;
}

.avatar:hover {
  filter: none;
  transform: scale(1.4);
}

.Education {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
}

.title {
  padding-bottom: 1vh;
}

@media (max-width: 1129px) {
  .flexContainer {
    display: flex;
    flex-direction: column;
  }
  .cardEducation {
    margin: 1vh 0vw;
  }
  .title {
    padding: 20px 0px;
  }
}

@media (max-width: 576px) {
  .flexContainer {
    display: flex;
    flex-direction: column;
  }
  .avatar {
    align-self: center;
    width: 15vw !important;
    height: 15vw !important ;

    margin: 5px 0px !important;

    padding: 2px 2px !important;
  }
  .name {
    text-align: center;
    font-size: 15px !important;
    padding: 5px 0px !important;
    margin: 0px 0px !important;
  }
}

@media (max-width: 376px) {
  .flexContainer {
    display: flex;
    flex-direction: column;
  }
  .avatar {
    align-self: center;
    width: 15vw !important;
    height: 15vw !important ;

    margin: 5px 0px !important;

    padding: 2px 2px !important;
  }
  .name {
    text-align: center;
    font-size: 10px !important;
    padding: 5px 0px !important;
    margin: 0px 0px !important;
  }
}
