.Footer {
  padding-top: 220px;
  padding-bottom: 150px;

  background: rgb(0, 114, 255);
  background: linear-gradient(
    198deg,
    rgba(0, 114, 255, 1) 0%,
    rgba(0, 198, 255, 1) 100%
  );
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.footerLogo {
  display: flex;
}

.footerSocail {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
}

.iconSocail {
  margin: 20px;
  width: 60px;
  height: 60px;
}

.linkdin {
  margin: 20px;
  width: 120px;
  height: 60px;
}

.iconSocailSmall {
  width: 35px;
  height: 35px;
  margin: 10px;
}

.footerCopyright {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.footerCopyrightTitle {
  font-size: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 992px) and (min-width: 769px) {
  .footerCopyrightTitle {
    font-size: 18px;
  }
}

@media (max-width: 768px) and (min-width: 577px) {
  .footerCopyrightTitle {
    font-size: 15px;
  }
  .footerTitle {
    font-size: 35px;
    padding: 15px 0px;
  }
  .iconSocailSmall {
    width: 25px;
    height: 25px;
    margin: 10px;
  }
}

@media (max-width: 576px) {
  .centerIcons {
    margin: auto;
  }
  .iconSocail {
    margin: 20px;
    width: 60px;
    height: 60px;
  }
  .linkdin {
    margin: 20px;
    width: 120px;
    height: 60px;
  }
  .footerCopyrightTitle {
    font-size: 18px;
  }
  .footerTitle {
    font-size: 15px;
    padding: 15px 0px;
  }
  .iconSocailSmall {
    width: 40px;
    height: 40px;
    margin: 20px 0px;
  }
  .footerCopyright {
    flex-direction: column;
  }
}
