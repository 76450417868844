* {
  box-sizing: border-box;
}

.introContainer {
  padding-top: 100px;
  margin: auto;
}

.introTitle {
  font-size: 60px !important;

  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.button {
  margin-right: 2vw !important;
  padding-left: 2vw !important;
  padding-right: 2vw !important;
  padding-top: 0.5vw !important  ;
  padding-bottom: 0.5vw !important  ;
}

.button:hover {
  -webkit-transform: scale(1.25, 1.25);
  transform: scale(1.25, 1.25);
}
.description {
  color: "#6f6f6f";
}

.expandContainer {
  visibility: hidden;
}

@media (max-width: 1100px) and (min-width: 993px) {
}

@media (max-width: 992px) and (min-width: 769px) {
  .introContainer {
    padding-top: 35px;
    margin: auto;
  }
  .buttonsContainer {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
  }
  .button {
    padding: 10px !important;
    margin: 10px !important;
  }
}

@media (max-width: 768px) and (min-width: 577px) {
  .introContainer {
    padding-top: 35px;
    margin: auto;
  }
  .buttonsContainer {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
  }
  .button {
    padding: 10px !important;
    margin: 10px !important;
  }
  .expand {
    width: 100px !important;
    height: 100px !important;
    color: #00c3ff;
  }
  .expandContainer {
    visibility: visible;
    margin: auto;
  }
}

@media (max-width: 576px) {
  .introContainer {
    padding-top: 15px;
    margin: auto;
  }
  .buttonsContainer {
    display: flex;
    flex-direction: column;
    padding: 5px 0px;
  }
  .button {
    padding: 8px !important;
    margin: 8px !important;
  }
  .introTitle {
    font-size: 35px !important;
  }
  .description {
    font-size: 15px !important;
  }
  .expand {
    width: 100px !important;
    height: 100px !important;
    color: #00c3ff;
  }
  .expandContainer {
    visibility: visible;
    margin: auto;
  }
}
