.logo {
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
}
.logo:hover {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.navBar {
  padding: auto;
}

.darkMode {
  transform: rotate(150deg);
}
