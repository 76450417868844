.skills {
  text-align: center;

  clip-path: polygon(0 20%, 100% 0, 100% 80%, 0% 100%);
  text-align: center;
}

.paddingTopP {
  padding-top: 15vh;
  padding-bottom: 15vh;
}
.skillImg {
  width: 25vh !important;
  height: 25vh !important;
}
.item {
}
.itemTitle {
  margin-top: 2vh;
  font-size: 1.6rem;
}

.row {
  display: flex;
}

@media (max-width: 645px) {
  .row {
    display: flex;
    flex-direction: column;
  }
  .paddingTopP {
    padding-top: 25vh;
    padding-bottom: 25vh;
  }
}
